<template>
  <el-dialog title="用户注册协议" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false"
    @close="handleClose" width="80vw" v-loading="loading" element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading">
    <div v-if="fileContent">
      <pre>{{ fileContent }}</pre>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import fileContentB from '@/assets/html/regiest.txt';
export default {
  name: 'AddPage',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      fileContent: '',
    }
  },
  created() {
      this.fileContent = fileContentB
  },
  methods: {
    handleClose() {
      // 关闭事件处理函数
      this.$emit('close');
    },
  }
}
</script>
<style>
pre {
  white-space: pre-wrap;
}
</style>