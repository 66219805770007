var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-spinner":"el-icon-loading","element-loading-text":"玩命加载中..."}},[_c('div',{staticStyle:{"border-bottom":"2px solid #dddddd"}},[_c('div',{staticClass:"register-main-top"},[_vm._m(0),_c('span',{staticStyle:{"position":"absolute","right":"0"}},[_c('span',{staticClass:"buttom-txt register-goLogin",staticStyle:{"margin-right":"10px"},on:{"click":function($event){return _vm.goNew('/')}}},[_vm._v("[返回首页]")]),_vm._v(" hi，欢迎来到嘉兴智行物联网技术有限公司！ ")])])]),_c('div',{staticClass:"container step-box"},[_c('div',{staticClass:"step-son",class:_vm.stepActive == 1 ? 'active-step' : 'first-step'},[_vm._v("1、注册")]),_c('div',{staticClass:"arrow-right",class:_vm.stepActive == 1 ? 'arrow-right-avtive' : ''}),_c('div',{staticClass:"arrow-left"}),_c('div',{staticClass:"step-son",class:_vm.stepActive == 2 ? 'active-step' : ''},[_vm._v("2、登录")])]),_c('div',{staticClass:"register-container"},[(_vm.stepActive == 1)?_c('el-form',{ref:"form",staticClass:"register-form",attrs:{"model":_vm.form,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"账号","prop":"username","rules":[
  { required: true, message: '请输入账号', trigger: 'blur' },
]}},[_c('el-input',{staticStyle:{"width":"250px"},attrs:{"size":"small","placeholder":"请输入账号"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('el-form-item',{attrs:{"label":"密码","prop":"password","rules":[
  { required: true, message: '请输入密码', trigger: 'blur' },
  { pattern: /^[a-z0-9,,?.]{6,}$/i, message: '最少输入6位，只能输入字母、数字，特殊字符：“,”、“?”、“.”', trigger: 'blur' },
]}},[_c('el-input',{staticStyle:{"width":"250px"},attrs:{"show-password":"","size":"small","placeholder":"请输入密码"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('el-form-item',{attrs:{"label":"确认密码","prop":"confirmPassword","rules":[
  { required: true, message: '请再次输入密码', trigger: 'blur' },
  { validator: _vm.validateConfirmPassword, trigger: 'blur' }
]}},[_c('el-input',{staticStyle:{"width":"250px"},attrs:{"show-password":"","size":"small","placeholder":"请输入密码"},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}})],1),_c('el-form-item',{staticStyle:{"position":"relative"}},[_c('el-button',{staticStyle:{"width":"250px"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.onSubmit}},[_vm._v("立即注册")])],1)],1):_c('div',[_c('p',[_vm._v("恭喜注册成功！"),_c('span',{staticStyle:{"font-size":"22px","color":"#e6a23c"}},[_vm._v(_vm._s(_vm.seconds))]),_vm._v(" 秒后自动跳转到登录")]),_c('p',{staticStyle:{"font-size":"12px","color":"#83b24c","cursor":"pointer"},on:{"click":function($event){return _vm.goNew('/login')}}},[_vm._v("如果你的浏览器没反应，请点击这里...")])])],1),_c('p',{staticClass:"buttom-txt"},[_vm._v("注册即表示同意 "),_c('span',{staticStyle:{"cursor":"pointer","color":"#83b24c"},on:{"click":function($event){return _vm.openDialog(2)}}},[_vm._v("《用户注册协议》")]),_c('span',{staticStyle:{"cursor":"pointer","color":"#83b24c"},on:{"click":function($event){return _vm.openDialog(1)}}},[_vm._v("《隐私协议》")])]),(_vm.stepActive == 1)?_c('p',{staticClass:"buttom-txt"},[_vm._v("已有账号？去"),_c('span',{staticClass:"register-goLogin",on:{"click":function($event){return _vm.goNew('/login')}}},[_vm._v("登录")])]):_vm._e(),_vm._m(1),_vm._m(2),_c('PrivacyAgreementDialog',{attrs:{"visible":_vm.dialogVisible},on:{"close":_vm.closeDialog}}),_c('regiestAgreement',{attrs:{"visible":_vm.dialogVisible2},on:{"close":_vm.closeDialog}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-logo"},[_c('img',{attrs:{"alt":"logo","src":require("../../public/LOGO.svg")}}),_c('span',[_vm._v("嘉兴智行物联网技术有限公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"buttom-txt"},[_vm._v("© 嘉兴智行物联网技术有限公司 "),_c('a',{staticClass:"a-herf",attrs:{"href":"http://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("浙ICP备15017943号-6 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"buttom-txt"},[_vm._v("地址：浙江省嘉兴市南湖区智慧产业园9幢5楼 "),_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v("客服电话：400-667-9990")])])
}]

export { render, staticRenderFns }