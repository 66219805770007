<template>
  <div v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="玩命加载中...">
    <div style="border-bottom: 2px solid #dddddd;">
      <div class="register-main-top">
        <div class="search-logo">
          <img alt="logo" src="../../public/LOGO.svg">
          <!-- <span>闪开</span><span style="font-family: FZLanTingHeiS-R-GB;">·</span><span>新媒</span> -->
          <span>嘉兴智行物联网技术有限公司</span>
        </div>
        <span style="position: absolute;right: 0;">
          <span class="buttom-txt register-goLogin" style="margin-right: 10px;" @click="goNew('/')">[返回首页]</span>
          hi，欢迎来到嘉兴智行物联网技术有限公司！
        </span>
      </div>
    </div>

    <div class="container step-box">
      <div class="step-son" :class="stepActive == 1 ? 'active-step' : 'first-step'">1、注册</div>
      <div class="arrow-right" :class="stepActive == 1 ? 'arrow-right-avtive' : ''"></div>
      <div class="arrow-left"></div>
      <div class="step-son" :class="stepActive == 2 ? 'active-step' : ''">2、登录</div>
    </div>

    <div class="register-container">
      <el-form class="register-form" ref="form" :model="form" label-width="80px" v-if="stepActive == 1">
        <el-form-item label="账号" prop="username" :rules="[
    { required: true, message: '请输入账号', trigger: 'blur' },
  ]">
          <el-input v-model="form.username" size="small" placeholder="请输入账号" style="width: 250px;"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" :rules="[
    { required: true, message: '请输入密码', trigger: 'blur' },
    { pattern: /^[a-z0-9,,?.]{6,}$/i, message: '最少输入6位，只能输入字母、数字，特殊字符：“,”、“?”、“.”', trigger: 'blur' },
  ]">
          <el-input v-model="form.password" show-password size="small" placeholder="请输入密码"
            style="width: 250px;"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword" :rules="[
    { required: true, message: '请再次输入密码', trigger: 'blur' },
    { validator: validateConfirmPassword, trigger: 'blur' }
  ]">
          <el-input v-model="form.confirmPassword" show-password size="small" placeholder="请输入密码"
            style="width: 250px;"></el-input>
        </el-form-item>
        <el-form-item style="position: relative;">
          <el-button type="primary" size="small" @click="onSubmit" style="width: 250px;">立即注册</el-button>
        </el-form-item>
      </el-form>
      <div v-else>
        <p>恭喜注册成功！<span style="font-size: 22px;color: #e6a23c;">{{ seconds }}</span> 秒后自动跳转到登录</p>
        <p style="font-size: 12px;color: #83b24c;cursor: pointer;" @click="goNew('/login')">如果你的浏览器没反应，请点击这里...</p>
      </div>
    </div>
    <p class="buttom-txt">注册即表示同意 
      <span style="cursor: pointer;color: #83b24c;" @click="openDialog(2)">《用户注册协议》</span>
      <span style="cursor: pointer;color: #83b24c;" @click="openDialog(1)">《隐私协议》</span>
    </p>
    <p v-if="stepActive == 1" class="buttom-txt">已有账号？去<span class="register-goLogin" @click="goNew('/login')">登录</span>
    </p>
    <p class="buttom-txt">© 嘉兴智行物联网技术有限公司 <a href="http://beian.miit.gov.cn/" target="_blank"
        class="a-herf">浙ICP备15017943号-6 </a></p>
    <p class="buttom-txt">地址：浙江省嘉兴市南湖区智慧产业园9幢5楼 <span style="margin-left: 20px;">客服电话：400-667-9990</span></p>

    <PrivacyAgreementDialog :visible="dialogVisible" @close="closeDialog">
    </PrivacyAgreementDialog>

    <regiestAgreement :visible="dialogVisible2" @close="closeDialog">
    </regiestAgreement>
  </div>
</template>

<script>
import PrivacyAgreementDialog from './components/privacyAgreement.vue';
import regiestAgreement from './components/regiestAgreement.vue';
export default {
  name: 'Register',
  components: {
    PrivacyAgreementDialog,regiestAgreement
  },
  data() {
    return {
      form: {
        username: '',
        password: '',
        confirmPassword: ''
      },
      stepActive: 1,
      seconds: 3,
      fromUrl: '',
      interval: null,
      loading: false,
      dialogVisible: false,
      dialogVisible2:false,
    }
  },
  created() {
  },
  mounted() {
    this.stepActive = 1
    // this.loadHTML();
    // this.loadTxtFile();
  },
  watch: {
    //点返回等
    '$route'(to, from) {
      // 处理网址变化
      // console.log(`从 ${from.path} 到 ${to.path}`);
      this.fromUrl = from.path
    }
  },
  methods: {
    openDialog(val){
      if(val == 1){
        this.dialogVisible = true
      }else{
        this.dialogVisible2 = true
      }
    },
    closeDialog(){
      this.dialogVisible = false
      this.dialogVisible2 = false
    },
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.form.password) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    },
    goNew(val) {
      if (this.$route.path !== val) {
        this.$router.push(val);
      }
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true
          let q = JSON.parse(JSON.stringify(this.form))
          delete q.confirmPassword
          this.$api.register(q).then(() => {
            this.stepActive = 2
            this.interval = setInterval(() => {
              if (this.seconds > 1) {
                this.seconds--;
              } else {
                clearInterval(this.interval)
                localStorage.removeItem('token')
                this.goNew('/login')
              }
            }, 1000);
          }).finally(() => {
            this.loading = false
          })

        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.register-main-top {
  position: relative;
  width: 80%;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.register-container {
  width: 40%;
  /* height: 400px; */
  margin: 0 auto;
  padding: 30px 0;
}

.search-logo {
  position: absolute;
  left: 0;
}

.search-logo img {
  margin-top: 10px;
  margin-right: -30px;
  vertical-align: middle;
}

.search-logo span {
  color: #2A2A2A;
  font-weight: 500;
  font-size: 24px;
  vertical-align: middle;
  font-family: YouYuan;
  margin-right: 5px;
}

.register-form {
  /* text-align: center; */
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}


.arrow-right {
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-left: 18px solid #a9c886;
}

.arrow-right-avtive {
  border-left: 18px solid #83b24c;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-left: 18px solid #f7f7f7;
  margin-right: -18px;
  z-index: 9;
}

.content {
  background-color: #000;
  color: #fff;
  /* padding: 10px; */
}

.step-box {
  width: 40%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  text-align: center;
  background: #f7f7f7;
}

.active-step {
  background-color: #83b24c !important;
  color: #fff;
}

.first-step {
  background-color: #a9c886 !important;
  color: #fff;
}

.step-son {
  width: 50%;
  padding: 8px 0;
  background-color: #eeeeee;
}

.el-button--primary {
  background: linear-gradient(180deg, rgb(172, 204, 137) 0%, #83b24c 100%);
  border-color: #83b24c;
}

.el-button--primary:focus,
.el-button--primary:hover {
  background: linear-gradient(180deg, rgb(172, 204, 137) 0%, #83b24c 100%);
  border-color: rgb(172, 204, 137);
  color: #FFF;
}

.register-goLogin {
  cursor: pointer;
  text-decoration: underline;
}

.register-goLogin:hover {
  color: red;
}

.buttom-txt {
  text-align: center;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  color: #9E9E9E;
}

.a-herf {
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  color: #9E9E9E;
  text-align: center;
}

.a-herf:hover {
  color: #6F9B3C;
}
</style>